import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FAQsSection from "./faqs";

export default function MicrorecLowerpart() {
  return (
    <Grid container>
      <Grid item xs={12} sx={{ paddingTop: "0px" }}>
        <Typography
          variant="body1"
          paragraph={true}
          gutterBottom={true}
          sx={{ textAlign: "right", fontSize: "18px", padding: "10px" }}
        >
          Need help?{" "}
          <a
            className="link_contact"
            href="/contact-us"
            target="_blank"
            style={{ color: "#8F4FFF", fontWeight: "bold" }}
          >
            Contact us
          </a>
        </Typography>
        <hr></hr>
      </Grid>
      <Grid container sx={{ marginTop: "2em" }} style={{ paddingLeft: "20px" }}>
        <Grid item xs={12} sm={4}>
          <Typography
            variant={"h5"}
            color={"primary"}
            sx={{ paddingBottom: 2, fontWeight: "bold" }}
          >
            Hardware Specifications
          </Typography>
        </Grid>
        <Grid
          item
          xs={11.5}
          sm={7.5}
          sx={{
            border: "1px solid black",
            borderRadius: "10px",
            padding: "20px",
            textAlign: "center",
          }}
        >
          <Grid container>
            <Grid xs={6}>
              <div style={{ fontWeight: "600" }}>Weight</div>
              <div>15g</div>
            </Grid>
            <Grid xs={6}>
              <div style={{ fontWeight: "600" }}>Depth</div>
              <div>80mm</div>
            </Grid>

            <Grid container sx={{ marginTop: "2em" }}>
              <Grid xs={6}>
                <div style={{ fontWeight: "600" }}>Max width</div>
                <div>40mm</div>
              </Grid>
              <Grid xs={6}>
                <div style={{ fontWeight: "600" }}>Min width</div>
                <div>14mm</div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ marginTop: "3em", marginBottom: "3em", paddingLeft: "20px" }}
      >
        <Grid item xs={12} sm={4}>
          <Typography
            variant={"h5"}
            color={"primary"}
            sx={{ paddingBottom: 2, fontWeight: "bold" }}
          >
            Introduction
          </Typography>
        </Grid>
        <Grid item xs={11.5} sm={7.5}>
          <div
            style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}
          >
            <iframe
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              src="https://www.youtube.com/embed/lUKiw03YSoI?si=iEyh2fFlwaL_n9eG"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </Grid>
      </Grid>

      <FAQsSection />
    </Grid>
  );
}
